import { commonEnv } from './environment.common';

export const environment = {
  ...commonEnv,
  environmentLabel: null,
  production: true,
  version: '1.9.177',
  commitHash: '177b44eabca5baef8cb31b6d42b367672ef0ec84',
  sentry: '',
  boomerangApiUrl: 'http://localhost:5001',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'boomerang',
    bearerExcludedUrls: ['http://localhost:4201'],
    scope: 'openid email profile offline_access',
    twoFaTimeLimit: 120,
    logoutUrl: 'http://localhost:4201',
  },
};
